import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    downloadFile: null,
  }
}
export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_DOWNLOAD_FILE(state, downloadFile) {
    state.downloadFile = downloadFile
  },
}

export const actions = {
  async fetchHeinsohnExport(store, payload = {}) {
    store.commit('UPDATE_DOWNLOAD_FILE', null)
    try {
      const response = await axios.post(
        `${BASE}/co/api/v2/dataExport/heinsohn`,
        payload,
      )
      store.commit(
        'UPDATE_DOWNLOAD_FILE',
        response.data.aggregates.fileInBase64,
      )
      return response
    } catch (e) {
      throw Error('API Error occurred in /co/api/v2/dataExport/heinsohn')
    }
  },
  async retryExportJob(store, payload = {}) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/dataExport/exportJobs/retry`,
        payload,
      )
    } catch (e) {
      throw Error(
        'API Error occurred in /co/api/v2/dataExport/exportJobs/retry',
      )
    }
  },
}
